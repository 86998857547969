<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="130px" class="ruleForm" size="small">
      <el-form-item label="选择门店" v-if="userInfo && userInfo.user_type==1" prop="store_id">
        <el-select v-model="shop_id" placeholder="请选择门店" size="small" @change="changeShop">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房租" prop="rent">
        <el-input v-model="info.rent" placeholder="请输入房租">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="员工宿舍" prop="employee_dormitory">
        <el-input v-model="info.employee_dormitory" placeholder="请输入员工宿舍">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="水电" prop="water_ele">
        <el-input v-model="info.water_ele" placeholder="请输入水电">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="停车" prop="stop">
        <el-input v-model="info.stop" placeholder="请输入停车">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="人员工资底薪" prop="base_pay">
        <el-input v-model="info.base_pay" placeholder="请输入人员工资底薪">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="社保" prop="social_security">
        <el-input v-model="info.social_security" placeholder="请输入社保">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="店里固定设备折旧" prop="equipment_depreciation">
        <el-input v-model="info.equipment_depreciation" placeholder="请输入店里固定设备折旧">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="每月耗材预估" prop="consume_material">
        <el-input v-model="info.consume_material" placeholder="请输入每月耗材预估">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="运费" prop="fee">
        <el-input v-model="info.fee" placeholder="请输入运费">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="每月摊销成本" prop="month_amortize_const">
        <el-input v-model="info.month_amortize_const" placeholder="请输入每月摊销成本">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="销售提成比例" prop="sale_commission_lv">
        <el-input v-model="info.sale_commission_lv" placeholder="请输入销售提成比例">
          <template slot="append"> % 输入0~100</template>
        </el-input>
      </el-form-item>
      <el-form-item label="营业日期" prop="business_date">
        <el-date-picker
            v-model="info.business_date"
            type="date"
            size="small"
            placeholder="请选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import util from "@/utils/util"
export default {
  data() {
    return {
      tableHeader: [],
      tableData: [],
      info: {
        id: 0,
        rent: '',
        employee_dormitory: '',
        water_ele: '',
        stop: '',
        base_pay: '',
        social_security: '',
        equipment_depreciation: '',
        consume_material: '',
        fee: '',
        business_date: '',
        month_amortize_const: '',
        sale_commission_lv: ''
      },
      userInfo: false,
      storeList: [],
      source_domain: this.config.SOURCE_DOMAIN,
      shop_id: '',
      rules: {
        store_id: [
          {required: true, message: '请选择门店', trigger: 'blur'}
        ],
      },
    };
  },
  created() {
    this.getMyInfo()
    this.getInfo()
  },
  mounted() {
  },
  components: {
  },
  computed: {},
  methods: {
    changeShop() {
      this.getInfo()
    },
    getInfo() {
      var that = this
      this.$api.operate.dailyCostDetail({store_id:this.shop_id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
        } else {
          that.info = {
            id: 0,
            rent: '',
            employee_dormitory: '',
            water_ele: '',
            stop: '',
            base_pay: '',
            social_security: '',
            equipment_depreciation: '',
            consume_material: '',
            fee: '',
            business_date: '',
            month_amortize_const: '',
            sale_commission_lv: ''
          }
        }
      })
    },
    getMyInfo() {
      this.$api.merchant.myIndex(res => {
        if(res.errcode==0) {
          this.userInfo = res.data
          if(res.data.user_type==1) {
            this.getStoreList()
          }
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = JSON.parse(JSON.stringify(this.info))
          if(param.sale_commission_lv<0 || param.sale_commission_lv>100) {
            this.fail('请输入正确的销售提成比例');
            return false
          }
          param.business_date = util.formatDate(new Date(param.business_date))
          this.$api.operate.dailyCostAdd(param, function (res) {
            if (res.errcode == 0) {
              that.success(res.errmsg)
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
  width: 60%;
}
</style>
